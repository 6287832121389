
.iti {
	display: block !important;
    position: relative;
}
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag, .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag, .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag, .iti.separate-dial-code .iti__selected-flag {
    width: 80px !important;
    position: absolute;
    top: 0;
    padding: 7px 5px;
    background: #d7d7d7;
    left: 0;
}
.iti__flag::after {
	position: absolute;
	content: url('../../assets/icon/down.svg');
	right: 10px;
	top: 6px;
}
.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
    box-shadow: 0px 2px 6px 0px #ccc;
}

.iti .iti__country-list {
	box-shadow: none;
	margin: 0;
    padding: 3px;
	width: 100%;
	max-height: 183px;
    list-style: none;
    overflow: auto;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}
li.iti__country {
    padding: 5px !important;
    border-bottom: 1px solid #ddd !important;
    color: #555 !important;
}
li.iti__country:last-child {
    border-bottom: none !important;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
    width: 100%;
	padding: 7px 7px 7px 90px !important;
    border: none;
	border-bottom: 1px solid #c7cace;
}
.iti .search-container input{
	padding: 5px 10px !important;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
