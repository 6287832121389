/* @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src:url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('.ttf');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src:url('../fonts/Open_Sans/OpenSans-SemiBold.ttf') format('.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src:url('../fonts/Roboto/Roboto-Regular.ttf') format('.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src:url('../fonts/Roboto/Roboto-Medium.ttf') format('.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: normal;
    src:url('../fonts/Roboto/Roboto-italic.ttf') format('.ttf');
} */
html{
    scroll-behavior:smooth;
}
body{
    width:100%;
    height: 100vh;
    min-height: 100%;
    margin:0 auto;
    font-size: 14px;
    background-color:#fff;
}
.bg-home {
    /* background: url(/assets/images/template/icons/bg-home.jpg) no-repeat; */
    background-size: cover;
    height: auto;
    min-height: 100vh;
    position: relative;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.bg-map{
    /* background: url(/assets/images/template/icons/map.jpg) no-repeat; */
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    position: relative;
}
.bg-header{
    font-size: 35px;
    font-style: normal;
    font-weight: normal;
    font-family:'Open Sans',sans-serif;
    padding-top:10%;
}
.brand-logo{
    margin: 15px 5px;
    display: inline-block;
}
.brand-logo >img{
    width: 80px;
    height:61px;
}
.navbar-brand img{
    width: 45px;
    height: 35px;
}
.info-option {
    margin-left: 70px;
    padding-top: 10px;
}
.list-group h4{
    color: #5a2c81;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
}
.list-group p{
    color:#555555;
    font-size:17px;
    font-family:'Roboto',sans-serif;
}
.opt-icon {
    margin-bottom: 10px;
    max-width:100%;
    width: 50px;
    height: auto;
}
.fixed-bottom{
    position:absolute;
    bottom:20%;
    left: 20%;
}
.fixed-bottom-right{
    position:fixed;
    bottom:10%;
    right: 3%;
}
.fixed-btn.right{
    position:relative;
    bottom: 2%;
    right: 0;
}
.comments a{
    width: 55px;
    height: 55px;
    padding: 17px 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    display: block;
    margin: 0 auto;
    background-color: #f8a05c;
}
.comments a>img{
    width:24px;
    height:24px;
}
.choose-country h5{
    color:#fff;
    font-size: 20px;
    font-weight: 500;
    font-family:'Open Sans',sans-serif;
    padding-left:5%;
}
.choose-country{
    padding: 5% 10% 10% 10%;
}
.choose-country .select-group{
    margin-top:25px;
    padding-bottom: 20px;
    /*-even spacing*/
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.choose-country .select-group>li>a > img{
    display: block;
    margin-bottom: 10px;
    height: 70px;
}
.choose-country .select-group>li>a{
    text-align: center;
    color: #fff;
    font-weight: bold;
    display: inline-block;
}
.choose-country .select-group>li.active>a,
.choose-country .select-group>li.active>a:focus,
.choose-country .select-group>li.active>a:hover{
    color: #444;
    text-decoration: none;
}
.choose-country .select-group>li>a:hover{
    color: #fff;
    text-decoration: none;
}
.select-group>.list-group-item{
    background-color: transparent;
    border-color: transparent;
}
.select-group>.list-group-item.active{
    background-color: #fff;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.navbar-light.top-menu {
    background-color: #fff;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active{
    color:#5a2c81!important;
}
.top-menu .navbar-nav .nav-item{
    border-right:1px solid #d8d0de;
    padding: 0 5px;
}
.top-menu .navbar-nav .nav-item:nth-child(3){
    border-right: none;
    position: relative;
}
.top-menu .navbar-nav .nav-item:nth-child(3):before{
    content:"";
    width:1px;
    height:20px;
    background-color: #d8d0de;
    display: inline-block;
    position:absolute;
    right:0;
    top:10px;
}
.top-menu .navbar-nav .nav-item:last-child{
    border-right:none;
}
.top-menu .navbar{
    padding: 0 2rem;
}
.top-menu .navbar-nav .dropdown-menu{
    min-width: 8rem;
    right: 0;
    left: inherit;
}
.top-menu .navbar-nav .nav-item.dropdown .nav-link.dropdown-toggle{
    padding: 4px 5px;
}
.top-menu .navbar-nav .nav-item.dropdown .nav-link.dropdown-toggle>img{
    width:30px;
    height:32px;
}
.navbar-nav .dropdown-menu .dropdown-item{
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 115px;
}
.textSelect{
    color:#f8a05c!important;
}
.top-menu .custom-select {
    display: inline-block;
    width: 95%;
    /* background: url(/assets/images/template/icons/down-arrow.svg) no-repeat; */
    background-color: transparent;
    background-position: right 55%;
    border: none;
    font-size: 14px;
    padding: 5px 15px 5px 8px;
    line-height: 28px;
}
.top-menu .dropdown-toggle::after{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/down-arrow.svg) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 16px;
    vertical-align: middle;
    height: 12px;
}
.top-menu .custom-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.input-group>.input-group-append>.input-group-text i{
    color: #f8a05c;
}
.input-group input::placeholder{
    font-size:14px;
    font-family:'Roboto',sans-serif;
}
.top-menu .form-inline .input-group{
    width:100%;
}
.bg-map .info-card {
    max-width: 43.5%;
    padding-left:4%;
}
.info-card h4, .info-card p{
    font-size: 22px;
    color:#5a2c81;
    font-family:'Roboto',sans-serif;
}
.arrow-next{
    margin-left: 50px;
}
.arrow-next-icon {
    width: 26px;
    height: 26px;
}
.footer-section p{
    padding: 10px 15px;
    margin-bottom: 0;
    font-size:13px;
    font-family:'Roboto',sans-serif;
}
.footer-nav li a {
    color:#666;
    font-size:14px;
    font-family:'Roboto',sans-serif;
}
.footer-nav li a:hover {
    color:#666;
    text-decoration: none;
}

#map {
    height: 100%;
}
#map button {
    visibility: hidden;
}

/*----modal tab design----*/
.modal .tabs{
    background-color:#fff;
    padding:0;
}

.modal .tabs .tab-pane{
    margin-left:20px;
    padding: 20px 0;
    min-height: 600px;
}

.modal .tabs h3{
    font-size:20px;
    margin-top:10px;
    margin-bottom:60px;
}

.modal .tabs p{
    font-size:14px;
}

.modal .tabs a{
    text-align: left;
    font-size:15px;
    font-family: "Open Sans",sans-serif;
    font-weight:700;
    color:#444;
    line-height: 35px;
}
.modal .tabs a:hover{
    text-decoration: none;
}
.modal .tabs li{
    background-color: #f5f5f5;
    margin:0;
    text-align: left;
    height:80px;
    width: 100%;
    padding:20px;
    -moz-border-radius: 0;
    -webkit-border-radius:0;
    border-radius:0;
    border-top: 1px solid #ddd;
}

.modal .tabs li.active{
    background-color:#fff;
}
.modal .modal-body{
    padding: 0 15px;
}
.modal .tabs .tab-header.active {
    background-color: #f5f5f5;
}

.modal .tab-header a{
    color:#5b2c82;
    padding: 0;
    font-size:15px;
    line-height: 15px;
    font-weight:500;
    font-family: "Open Sans", sans-serif;
}
.modal .tab-header{
    text-align: left;
}
.btn-orange{
    padding: 8px 20px;
    background-color:#f8a05c;
    border-color:#f8a05c;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius:20px;
    font-size:14px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
}
.btn-orange:hover,.btn-orange:focus,.btn-orange:active,.btn-orange:active:focus{
    background-color:#f98d3a!important;
    border-color:#f98d3a!important;
    box-shadow: none!important;
}
.modal-footer{
    border:none;
}
.modal .prev-next i{
    color:#c4c4c4;
    padding: 5px;
}
.modal .tabs .badge-pill {
    color: #888888;
    font-weight: normal;
    background-color: #ffffff;
    padding: 10px 20px;
    margin: 0 10px;
    border: 1px solid #ededed;;
}
.modal .tabs .badge-pill.active{
    background-color:#ededed;
    color:#888888;
}
.modal input.building{
    border-radius:0;
    max-width: 275px;
    width: 100%;
    height: 30px;
}
.modal .tabs input.form-control{
    height:30px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius:0;
}
.divider{
    border-bottom:1px solid #e3e3e3;
    margin-bottom: 15px;
}
.badge-pill.circle{
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius:50%;
    padding:10px 12px!important;
    position:relative;
}
.info-pill {
    line-height: normal;
    margin: 5px auto;
    display: table;
    padding: 5px 8px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 10px;
    color: #888;
    background-color: #ededed;
    font-family: 'Roboto',sans-serif;
}
.badge-item{
    height:60px;
    text-align: center!important;
}
.apartment .badge-pill{
    margin: 0 4px!important;
}
.pro-type{
    background-color: #f5f5f5;
    border-right:1px solid #eee;
}
.amenities p{
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
}
.amenities p span{
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
}
.amenities .icon-circle{
    background-color: #ebebeb;
    width:40px;
    height:40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius:50%;
    display: table;
    margin: 0 auto;
    text-align: center!important;
}
.amenities .icon-circle img {
    width: 20px;
    height: auto;
}
.amenities .list-group >li{
    background-color: transparent!important;
    border:none;
    text-align: center;
    font-size: 12px;
    height: auto;
    font-family: 'Roboto',sans-serif;
    padding: 5px 10px;
    width: 33.333%;
}
.amenities .list-group.list-group-horizontal{
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.font-sm{
    font-size: 15px;
}
.modal.property-type .close{
    margin: 5px 0;
}
.secondary-navbar{
    background-color: #5a2c81;
    padding: 0 16px;
    top:61px;
    z-index: 1;
}
.secondary-navbar li a{
    color:#fff;
    font-size: 12px;
    font-weight:500;
    text-transform: uppercase;
    font-family: 'Open Sans',sans-serif;
    letter-spacing: 0.6px;
}
.secondary-navbar .nav-first .nav-item{
    border:none;
    padding: 10px 3px;
}
.secondary-navbar .nav-first .nav-item:hover,
.secondary-navbar .nav-first .nav-item.active{
    background-color: #00c1de;
}

.secondary-navbar .nav-first .nav-item:nth-child(3):before{
    background-color: transparent;
}
.secondary-navbar .nav-last .nav-item{
    border-right: 1px solid #7b4da2;
}
.secondary-navbar .navbar-nav .nav-item:last-child {
    border-right: none;
}
.secondary-navbar .nav-last .nav-item >a{
    padding: 18px 5px;
}
.secondary-navbar .nav-last .nav-item.active{
    background-color: #00c1de ;
}
.secondary-navbar .nav-last .nav-item:nth-child(3):before{
    background-color: transparent;
}

.secondary-navbar .nav-last .nav-item >a.save:before{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/Save_icon.svg) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-size: 100%;
    margin-right: 8px;
}
.secondary-navbar .nav-last .nav-item.active >a.save:before{
    /* background: url(/assets/images/template/icons/Save_icon_white.svg) no-repeat; */
    background-size: 100%;
}
.secondary-navbar .nav-last .nav-item >a.follow:before{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/Follow_icon.svg) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-size: 100%;
    margin-right: 8px;
}
.secondary-navbar .nav-last .nav-item.active >a.follow:before{
    /* background: url(/assets/images/template/icons/Follow_icon_white.svg) no-repeat; */
    background-size: 100%;
}
.secondary-navbar .nav-last .nav-item >a.share:before{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/share_icon.svg) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-size: 100%;
    margin-right: 8px;
}
.secondary-navbar .nav-last .nav-item.active >a.share:before{
    /* background: url(/assets/images/template/icons/share_icon_white.svg) no-repeat; */
    background-size: 100%;

}
.secondary-navbar .nav-last .nav-item >a.mail:before{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/Mail_me.svg) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 16px;
    height: 16px;
    vertical-align: -3px;
    background-size: 100%;
    margin-right: 8px;
}
.secondary-navbar .nav-last .nav-item.active >a.mail:before{
    /* background: url(/assets/images/template/icons/Mail_me_white.svg) no-repeat; */
    background-size: 100%;
}
.pro-wrap{
    z-index:1;
    top: 220px;
    left: 0;
    position: fixed;
    height: auto;
    border: none;
    min-height: 100%;
    border-right: 2px solid #eee;
}
.pro-edit {
    padding: 20px;
    background-color: #eee;
    top: 115px;
    position: fixed;
    z-index: 999;
    width:50%;
}
.pro-value .title-lg{
    font-size:22px;
    font-weight:bold;
    font-family: 'Roboto',sans-serif;
}
.pro-value .title-lg span{
    font-size: 13px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
span.dashed {
    width: 60px;
    display: inline-block;
    border-top: 2px solid #d3cadc;
    padding-bottom: 5px;
}
.pro-edit h5{
    color:#666666;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Open Sans',sans-serif;
}
.pro-edit h5 span{
    padding-left:20px;
    color:#666666;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto',sans-serif;
}
.section-header{
    width: 100%;
    left: 0;
    bottom: -7px;
    border-bottom: 1px solid #bebebe;
    display: block;
    margin-bottom:25px;

}
.pro-desc {
    padding: 20px;
}
.section-header {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans',sans-serif;
}
.section-header span{
    position: absolute;
    top: -15px;
    left: 0;
    background: #fff;
    padding-right: 15px;
    z-index: 1;
}
.list-item li{
    font-size: 15px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
.title{
    font-size: 15px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
.card-danger{
    color: #fff;
    background-color: #f78e79;
    border-color: #f78e79;
    display: inline-block;
    margin-bottom: 20px;
}
.card-danger .card-body{
    padding:8px;
    font-size: 12px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
.pro-wrap .bg-gray{
    background-color: #f3f3f3;
    height:auto;
    padding: 30px 20px;
}
.bg-gray .section-header span{
    background-color: #f3f3f3;
}
.pro-wrap .list-group-item.active{
    z-index: 2;
    color: #666666;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
}
.pro-wrap .list-group-item .badge{
    font-size: 14px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
.location .list-group-item{
    background-color: transparent;
}
.pro-wrap .location .list-group-item.active{
    background-color: #ffff;
    border: 1px solid rgba(0,0,0,.125);
    border-right: 0;
    border-left: 0;
}
.location .info-data .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    border: 1px solid #5a2c81;
    color:#5a2c81;
}
.btn-circle {
    width: 45px;
    height: 45px;
    padding: 12px 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    display: block;
    margin: 3px auto;
    background-color: #ebebeb;
}
.list-group.icon-group li{
    width: 17%;
    padding: 10px;
    border:none;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
}
.list-group.icon-group{
    padding-bottom:20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.icon-group .btn-circle img{
     width: 20px;
     height: 20px;
 }
.icon-group .btn-circle.active,.btn-circle-lg.active{
    background-color: #5a2c81;
}
.pro-type-amenities .list-group-item.swimming .btn-circle:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/swimming.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background-size: 100%;
}
.pro-type-amenities .list-group-item.swimming .btn-circle.active:before{
    background: url(/assets/images/template/icons/swimming_white.svg) no-repeat;
    background-size: 100%;
}
.pro-type-amenities .list-group-item.garden .btn-circle:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/Garden.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background-size: 100%;
}
.pro-type-amenities .list-group-item.garden .btn-circle.active:before{
    background: url(/assets/images/template/icons/Garden_white.svg) no-repeat;
    background-size: 100%;
}
.btn-circle-lg{
    width: 134px;
    height: 134px;
    padding: 12px 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    display: block;
    margin: 3px auto;
    background-color: #fff;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.location .icon-group{
    -webkit-justify-content: space-around;
    justify-content: space-around;
}
.location .icon-group li{
    width:auto;
}
.location .list-group-item.apartment .btn-circle-lg:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/apartment.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    background-size: 100%;
}
.location .list-group-item.apartment .btn-circle-lg.active:before{
    background: url(/assets/images/template/icons/apartment_white.svg) no-repeat;
    background-size: 100%;
}
.location .list-group-item.retail .btn-circle-lg:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/retail.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    background-size: 100%;
}
.location .list-group-item.retail .btn-circle-lg.active:before{
    background: url(/assets/images/template/icons/retail_white.svg) no-repeat;
    background-size: 100%;
}
.location .list-group-item.offices .btn-circle-lg:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/Office.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    background-size: 100%;
}
.location .list-group-item.offices .btn-circle-lg.active:before{
    background: url(/assets/images/template/icons/Office_white.svg) no-repeat;
    background-size: 100%;
}
.location .list-group-item.industry .btn-circle-lg:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/industry_icon.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    background-size: 100%;
}
.location .list-group-item.industry .btn-circle-lg.active:before{
    background: url(/assets/images/template/icons/industry_icon_white.svg) no-repeat;
    background-size: 100%;
}
.btn-edit:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/edit.svg) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    background-size: 100%;
    margin-right: 5px;
}
.photosGallery{
    padding:20px;
    background-color: transparent;
    margin-bottom: 20px;
}
/*--photo Gallery--*/
.photosGallery .flex-column {
    max-width: 100%;
}

.photosGallery img {
    margin: 0;
    width: 100%;
    height: 100%;
}

.photosGallery .scale {
    transform: scaleY(1.05);
    padding-top: 5px;
}
footer{
    z-index: 999999;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #eee;
}

/*----login form---*/
#login-row #login-column #login-box {
    margin-top: 120px;
    max-width: 600px;
    height: 320px;
    border: 1px solid #9C9C9C;
    background-color: #EAEAEA;
}
#login #login-row #login-column #login-box #login-form {
    padding: 20px;
}
#login-form #register-link {
    color:#00c2de;
    font-size:13px;
}
#login-form #register-link a:hover{
    text-decoration: none;
}
.custom-checkbox label{
    font-size:14px;
}
#login-column.card{
    border:none;
}
#login-form .input-group{
    border-bottom: 1px solid #ddd;
}
#login-form .input-group>.form-control{
    border:none;
}
#login-form .input-group .input-group-addon{
    padding: 5px;
    line-height: 28px;
}
#login-form h3{
    color:#5a2c81;
    font-size: 22px;
    font-weight: normal;
    font-family: 'Open sans',sans-serif;
}
#login-form .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #e4e4e4;
    background-color: #e4e4e4;
    border-radius: 0;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 12px;
    color: #666;
    display: inline-block;
    background: none;
    border: none;
    vertical-align: middle;
    background-size: 100%;
    margin: 0 auto;
    text-align: center;
}
#login-form .form-control:focus{
    box-shadow:none;
}
.social-login{
    padding: 10px 20px;
    background-color: #eee;
}
.social-login .social-icon{
    margin: 0 15px;
    width: 55px;
    height: 55px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 16px 12px;
}
.social-icon img{
    width:20px;
    height:20px;

}
.social-login p{
    color:#888;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    line-height: 45px;
    font-family: 'Roboto',sans-serif;
}
.sign-up{
    font-size: 13px;
}
.sign-up a{
    color:#5a2d82;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
}
.sign-up a:hover{
    text-decoration: none;
}
#login-form .input-group-addon.email-icon:before{
    content: "";
    display: inline-block;
    background: url(/assets/images/template/icons/email.png) no-repeat;
    background-color: transparent;
    background-position: right;
    border: none;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    background-size: 100%;
}
#login-form .input-group-addon.lock-icon:before{
    content: "";
    display: inline-block;
    /* background: url(/assets/images/template/icons/lock.png) no-repeat; */
    background-color: transparent;
    background-position: right;
    border: none;
    width: 14px;
    height: 18px;
    vertical-align: middle;
    background-size: 100%;
}
.bg-pattern{
    display: inline-block;
    /* background: url(/assets/images/template/icons/card-bg.jpg) no-repeat; */
    background-color: transparent;
    background-position: center;
    border: none;
    width: 100%;
    height: 100%;
    min-height:240px;
    vertical-align: middle;
    background-size: cover;
}
.card-first img{
    width:100px;
    height:100px;
}
.card-first h6{
    color:#5a2d82;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Open Sans',sans-serif;
    padding-top: 50px;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}
.card-btn-md{
    width:124px;
}
.card-btn-lg{
    width:150px;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
#login-box .close span:before{
    content:"";
    display: inline-block;
    /* background: url(/assets/images/template/icons/Cross.svg) no-repeat; */
    background-color: transparent;
    background-position: center top;
    border: none;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    background-size: 100%;
    opacity: 0.9;
}
#login-box .close span:focus,#login-box button{
    outline:none;
    opacity: 1;
}
#login-box .form-control{
    border-radius:0;
}
.form-control::placeholder{
    color:#999;
    font-size: 13px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
.bg-blue{
    background-color: #3b5998!important;
}
.btn-lightBlue {
    padding: 8px 20px;
    background-color: #00c2de;
    border-color: #00c2de;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: normal;
    font-family: 'Roboto',sans-serif;
}
/*---chart design----*/
#chart #numbers {
    padding: 0;
}
#chart #bars {
    padding-left: 20px;
    left: 80px;
}
#chart #bars li {
    width: 14%;
}
#bar-title {
    display: block;
    height: auto;
    padding-left: 10%;
}
#bar-title li {
    width: 10.8%!important;
}
#bar-title li span {
    width: 25px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    color: #555!important;
}
#chart #numbers:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 100%;
    display: inline-block;
    background: transparent;
    z-index: 0;
    border-right: 1px solid #ddd;
}
#chart #numbers li:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    display: block;
    z-index: -1;
    background-color: #eee;
}
#myinfo span{
    margin-right:10px;
}
#myinfo p{
    font-size: 13px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    padding-top: 10px;
}
.gm-style .gm-style-iw-c{
    padding:5px 15px 5px 5px!important
}
.p-30{
    padding:30px 20px!important;
}
.scroll-content{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom:30px;
}
/*----new version design---*/
.secondary-navbar.nav-style-2 {
    top: 60px;
}
.nav-style-2 li a {
    text-transform: capitalize;
    font-size: 13px;
}
/*------responsive media----------*/

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px;
        margin: 1.75rem auto;
    }
    .modal-form .modal-dialog {
        max-width: 410px;
        margin: 1.75rem auto;
    }
    .modal-md .modal-dialog{
        max-width: 490px;
        padding-bottom: 30px;
    }
}
@media screen and (max-width:1100px){
    .secondary-navbar li a{
        font-size: 10px;
    }
    .pro-edit,.pro-wrap{
        width:64%;
        max-width: 64%;
    }
    .pro-edit{
        top: 113px;
    }
    .secondary-navbar.nav-style-2 li a {
        font-size: 11px;
    }
}
@media screen and (max-width:992px){
    .select-group>.list-group-item{
        padding: 5px 10px;
    }
    .bg-header{
        font-size: 24px;
    }
    .info-option .list-group h4{
        font-size: 20px;
    }
    .info-option .list-group p{
        font-size: 14px;
    }
    .info-option{
        margin-left: 15px;
    }
    .choose-country {
        padding: 5% 5% 10% 5%;
    }
    .secondary-navbar .nav-first .nav-item {
         padding: 10px 3px;
     }
    .pro-edit .btn-orange{
        font-size: 10px;
    }

}
@media screen and (max-width:900px){
    .pro-edit{
        position: inherit;
        width: 100%;
        top:0;
    }
    .secondary-navbar{
        top:0;
    }
    .top-menu.fixed-top{
        position: inherit;
    }
    .secondary-navbar{
        position: inherit;
        top:0;
    }
    .secondary-navbar .navbar-nav{
        flex-direction: inherit;
        margin: 0 auto;
    }
    .pro-wrap{
        position: inherit;
        top:0;
    }
    .secondary-navbar .nav-first .nav-item {
        border: none;
        padding: 10px 5px;
    }
    .secondary-navbar .nav-last .nav-item >a {
        padding: 18px 5px;
        text-align: center;
    }
    .secondary-navbar .nav-last .nav-item >a.mail:before{
        display: block;
        margin: 0 auto;
    }

}

@media screen and (max-width:768px){
    .secondary-navbar .nav-last .nav-item {
        border-right:none;
    }
    .pro-edit{
        position: inherit;
        width: 100%;
        top:0;
    }
    .secondary-navbar{
        top:0;
    }
    .top-menu.fixed-top{
        position: inherit;
    }
    .secondary-navbar{
        position: inherit;
        top:0;
    }
    .secondary-navbar .navbar-nav{
        flex-direction: inherit;
        margin: 0 auto;
    }
    .pro-wrap{
        position: inherit;
        top:0;
    }
    .secondary-navbar .nav-first .nav-item {
        border: none;
        padding: 10px 12px;
    }
    .pro-edit, .pro-wrap{
        width:100%;
        max-width:100%;
    }
    .secondary-navbar .nav-last .nav-item >a.mail:before{
        display: inline-block;
        margin-right: 8px;
    }
}

@media screen and (max-width:575px){
    .modal.property-type .modal-body{
        padding:0;
    }
    .modal.property-type .tabs .row{
        margin:0;
        width: 100%;
    }
    .modal.property-type .close {
        margin: 0 5px;
    }
    .photosGallery img {
        height: auto;
    }
    .choose-country .select-group {
        margin-top: 50px;
        padding-bottom: 20px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}




/* chartjs css */

#chart {
    width: 100%;
    height: 100%;
    margin: 10px auto 0;
    display: block;
    position: relative;
    padding-bottom: 30px;
  }
  
  #chart #numbers {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
  }
  
  #chart #numbers li {
    position: relative;
    text-align: left;
    padding-right: 1em;
    list-style: none;
    height: 42px;
    bottom: 30px;
  }
  
  #chart #numbers li span {
    color: #797a7d;
    position: absolute;
    bottom: -8px;
    left: 0;
  }
  
  #chart #bars {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    position: absolute;
    bottom: 0;
  }
  
  #chart #bars li {
    display: block;
    width: 10%;
    height: 211px;
    margin: 0;
    text-align: center;
    position: relative;
    float: left;
  }
  
  #chart #bars li .bar {
    display: block;
    width: 24px;
    margin-left: 0;
    background: #00c1de;
    position: absolute;
    bottom: 5px;
    left: 0;
    border-radius: 0;
  }
  
  #chart #bars li .bar:hover {
    background: #5AE;
    cursor: pointer;
  }
  
  #chart #bars li .bar:hover:before {
    color: white;
    content: attr(data-percentage) '%';
    position: relative;
    bottom: 20px;
  }
  
  #chart #bars li span {
    color: #eee;
    width: 100%;
    position: absolute;
    bottom: -2em;
    left: 0;
    text-align: center;
  }
  .chart-area{
    height: 227px;
    position: relative;
  }
  #bar-title li{
    list-style: none;
    color:#fff;
    float:left;
    width: 7.05%;
  }
  #bar-title li span{
    color:#fff;
    display: block;
    width: 30px;
    height: 16px;
    text-align: center;
    font-size: 9px;
    margin: 0 auto;
  }
  #bar-title {
    display: block;
  }
  #chart #bars li:nth-child(even) .bar{
    background: #e2e2e2!important;
  }
  #chart #bars li:nth-child(7) .bar{
    background: #7cc24c!important;
  }
  
/* chartjs css */