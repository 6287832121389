/* You can add global styles to this file, and also import other style files */
/*@import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */

@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css');
@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/themes/default.css');
@import 'assets/css/animate.css';
@import 'material-design-icons/iconfont/material-icons.css';
@import 'assets/css/style.default.css';
@import 'assets/css/estaterBootstrap.css';
@import 'assets/css/phone_number.css';
@import 'assets/css/template-style.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';


@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/font/OpenSans-Bold.ttf") format('.ttf');
  }
  
  @font-face {font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/font/OpenSans-Regular.ttf') format('.ttf');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/font/OpenSans-SemiBold.ttf') format('.ttf');
  }
  
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/font/OpenSans-Light.ttf') format('.ttf');
  }
  
  
  

body {
    padding: 0;
    margin: 0;
}

/* All CSS Used for migration to v15 */ 
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fff !important;
}
.mat-mdc-card-content {
    display: block;
    padding: 0 !important; 
}
.mdc-button .mdc-button__label {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.mat-mdc-card-header .mat-mdc-card-title {
    font-size: 20px;
    padding: 0px 10px;
    @media screen and (max-width:768px) {
        padding: 0px 4px;
        
    }
}
.mat-h2, .mat-title, .mat-typography h2 {
    font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
    padding: 10px 5px;
}

/* The End */
.cp{
    text-transform: capitalize!important;
}
.rel{
    position: relative!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 0px;
    color: #f69230!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-inner-container {
    height: 15px!important;
    width: 15px!important;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    flex-shrink: 0;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
  }
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-frame {
    border-color: #f69230!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #f69230!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value{
    padding-left: 5px;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label{
    min-width: 100%!important;
}
.border-blue{
	border : 2px solid #16ceed !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep.mat-form-field-infix {
    min-height: 40px;
}
.mat-drawer-inner-container {
    width: 100%;
    height: 100%;
    overflow-y: auto!important;
    overflow-x: hidden!important;
}
textarea:focus, input:focus, button:focus{
    outline: none !important;
}

/* =======================================================DO NOT CROSS================================================================ */
/* estater colors-------------------------------------------------- */
.est-p{
    color: #f69230!important;
}
.est-p-bg{
    background: #f69230!important;
}
.est-s{
    color: #16ceed!important;
}
.est-s-bg{
    background: #16ceed!important;
}
.est-g{
    color: #23cb6b!important;
}
.est-g-bg{
    background: #23cb6b!important;
}
.est-gr{
    color: #9d9fae!important;
}
.est-dgr{
    color: #525355!important;
}
.est-gr-bg{
    background: #9d9fae!important;
}
.est-lgr{
    color: #f3f4f9!important;
}
.est-lgr-bg{
    background: #f3f4f9!important;
}
.est-w{
    color: #fff!important;
}
.est-w-bg{
    background: #fff!important;
}
/* estater colors-------------------------------------------------- */

/* common classes start here-------------------------------------------------------------------------- */
.est-li-label{
    position: absolute;
    font-size: 11px;
    bottom: -25px;
    left: -21px;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #4b4b50!important;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.est-li{
    position: relative;
}
.est-li-nostyle{
    list-style: none;
}
.est-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 100px;
}
.est-title{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.est-hr{
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #f69230;
}
.est-con{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 5px 0;
}
.est-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.est-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.est-h1{
    font-size: 16px;
    font-weight: 700;
    color: #f69230;
    text-transform: capitalize;
}
.est-divide{
    width: 100%;
    margin: 0px 10px;
}
.est-subtitle{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.est-para{
    width: 100%;
}
.est-text{
    font-size: 12px;
    font-weight: normal;
    color: #272727;
}
.est-h4{
    font-size: 14px;
    font-weight: 600;
    color: #5e5d5d;
    text-transform: capitalize;
}
.custom-warn{
    background-color: rgb(245, 172, 36) !important;
    color:  #fff !important;
  }
 .custom-success{
    background-color: rgb(86, 196, 23) !important;
    color: #fff !important;
  }
 .custom-info{
    background-color: rgb(100, 204, 252)!important;
    color: #fff !important;
  }
   .custom-error{
    background-color:rgb(247, 86, 58) !important;
    color: #fff !important;
  }
.mat-mdc-snack-bar-action button {max-height: 36px;min-width: 0;color:  #fff !important;}
.angular-editor-toolbar button {color: #27325f;}
.ae-font .ae-picker-label {line-height: 1 !important;font-size: 100% !important;}
.angular-editor-toolbar .angular-editor-button.active:hover, .angular-editor-toolbar .angular-editor-button.active {background-color: #f69230 !important;color:  #fff !important;}
/* common classes end here-------------------------------------------------------------------------- */

/* =======================================================DO NOT CROSS================================================================ */
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
    background-color: transparent !important;
  }


.user_name{
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 125px;
}
.img-profile {
    object-fit: cover;
    width: 30px;
    height: 30px;
}

.csv-icon-highlight-fix{
    background: white !important;
    display: inline-grid !important;
}
.mat-mdc-select-trigger {
    height: 100%;
}
th.mat-mdc-header-cell{
    font-weight: normal !important;
}
.show-deleted_custom {
    background-color: #fff;
    color: black;
    margin-right: 12px;
    border-radius: 3px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px 2px;
  }
  .show-deleted_custom .row {
    padding: 5px 10px;
    font-weight: 500;
  }
  
  .sidenav ::-webkit-scrollbar-thumb, .sidenavsmall ::-webkit-scrollbar-thumb {
    background: rgb(214 213 212 / 30%) !important;
}
  .sidenav ::-webkit-scrollbar-thumb:hover, .sidenavsmall ::-webkit-scrollbar-thumb:hover {
    background: rgb(214 213 212 / 80%) !important;
} 
.close.close_icon {
    top: 3px !important;
    width: 14px;
    height: 24px;
    cursor: pointer;
}
.blog_list thead th:first-child{
    width: 64px;
}
.blog_list  thead th:last-child{
    width: 95px;
}
.blog_list thead th:nth-child(2) {
    min-width: 150px;
}
.blog_list thead th:nth-last-child(2) {
    width: 100px;
}
.blog_list thead th:nth-last-child(3) {
    min-width: 150px;
}

@media screen and (max-width:1024px) {
    .mat-mdc-paginator-container {
    justify-content:center !important;
    
    }
    @media screen and (max-width:1024px){
        .w-90 {
            width: 90% !important;
          }
        
    }
}
